<template>
  <div>
    <breadcrumb name="Spare Parts" title="All Spare Parts" subtitle="List"/>
    <div id="data-list-thumb-view" class="data-list-container">

      <vs-table ref="table" pagination :max-items="itemsPerPage" search
                :data="products">

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

          <div class="flex flex-wrap-reverse items-center">
          </div>
          <table-header
            :current-page="currentPage"
            :items-per-page="itemsPerPage"
            :length="products.length"
            :queried-items="queriedItems"
            @onChangeItemsPerPage="itemsPerPage = $event"/>
        </div>

        <template slot="thead">
          <!--          <vs-th>Image</vs-th>-->
          <vs-th sort-key="title">Name</vs-th>
<!--          <vs-th sort-key="brand">Brand</vs-th>-->
          <vs-th sort-key="model">Model</vs-th>
          <vs-th sort-key="availability">Availability</vs-th>
          <vs-th sort-key="price">Price</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <!--            <image-row :data="tr.image"/>-->
            <string-row :data="tr.title_en"/>
<!--            <string-row :data="tr.brand || 'Undefined'"/>-->
            <string-row :data="tr.model"/>
            <status-row :data="tr.mobile_active"/>
            <string-row :data="tr.price + ' LE'"/>
            <actions-row @onEditClicked="$emit('edit', tr)" :deletable="false" :show="tr.url !== ''"
                         @onShowClicked="openLink(tr.url)"/>

          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
  import {ChevronsRightIcon, HomeIcon} from 'vue-feather-icons';
  import Breadcrumb from "../../../../components/general/breadcrumb";
  import TableHeader from "../../../../components/table/table-header";
  import ImageRow from "../../../../components/table/rows/image-row";
  import StringRow from "../../../../components/table/rows/string-row";
  import StatusRow from "../../../../components/table/rows/status-row";
  import ActionsRow from "../../../../components/table/rows/actions-row";

  export default {
    name: 'view-parts',
    components: {ActionsRow, StatusRow, StringRow, ImageRow, TableHeader, Breadcrumb, HomeIcon, ChevronsRightIcon},
    data() {
      return {
        selected: [],
        mainData: {},
        // products: [],
        itemsPerPage: 20,
        isMounted: false,
        addNewDataSidebar: false,
        sidebarData: {},
        part: {}
      };
    },
    props: {
      parts: {
        required: true,
        default: []
      }
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      products() {
        return this.parts;
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length;
      }
    },
    methods: {
      openLink(link) {
        if (!/^http/.test(link)) {
          window.open("http://" + link, "_blank");
        } else {
          window.open(link, "_blank");
        }

      },
      getOrderStatusColor(status) {
        if (status == 0) return 'warning';
        if (status == 1) return 'success';
        return 'primary';
      },
      getOrderStatusState(status) {
        if (status == 0) return 'Unavailable';
        if (status == 1) return 'Available';
        return 'Unknown';
      }
    },
    mounted() {
      this.isMounted = true;
    }
  };
</script>

<style lang="scss">

  @import "../../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }


  #data-list-thumb-view {
    .vs-con-table {

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;


        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 10px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }

            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 110px;
              }
            }
          }

          td.td-check {
            padding: 20px !important;
            display: none !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
          display: none !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }

  .product-img {
    height: 110px;
    width: 110px;
    object-fit: cover;
  }
</style>
