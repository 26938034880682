<template>
    <div>
        <view-parts v-if="!isEditing" :parts="parts" @edit="editData"/>
        <edit-parts v-if="isEditing" :part="part" @cancel="isEditing = false" @update="getParts"/>
    </div>
</template>

<script>
    import ViewParts from './view-parts';
    import EditParts from './edit-parts';

    export default {
        name: 'spare-parts',
        components: { EditParts, ViewParts },
        data(){
            return {
                isEditing : false,
                parts: [],
                part: {}
            }
        },
        methods: {
            getParts() {
                this.$vs.loading();
                this.$httpAbout.get(`sparepart`)
                    .then(r => {
                        this.mainData = r.data.data;
                        this.$httpAbout.get(`sparepart?per_page=${this.mainData.total}`)
                            .then(r => {
                                this.$vs.loading.close();
                                this.parts = r.data.data.data;
                            })
                            .catch(() => {
                                this.$vs.loading.close();
                                //console.log(e);
                            });
                    })
                    .catch(() => {
                        //console.log(e);
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: 'Error',
                            text: 'Couldn\'t Load Data!',
                            color: 'danger'
                        });
                    });
            },
            editData(item){
                this.part = item;
                this.isEditing = true;
            }
        },
        mounted() {
            this.getParts();
        }
    };
</script>

<style lang="scss">


</style>
