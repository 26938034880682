<template>
    <div>
        <vx-card title="Info" class="mb-base">

            <!-- Avatar -->
            <vs-row vs-type="flex">
<!--                <vs-col-->
<!--                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="12">-->
<!--                    <div class="vx-col-2" id="avatar-col">-->
<!--                        <div class="img-container mb-4">-->
<!--                            <img :src="part.image" class="rounded w-full product-img img-fluid" alt="..."-->
<!--                                 onerror="this.onerror=null;this.src='https://uwosh.edu/facilities/wp-content/uploads/sites/105/2018/09/no-photo.png';"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </vs-col>-->
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="11" vs-offset="1">
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <vs-switch color="success" class="switch" v-model="activated" style="visibility:hidden;">
                            <span slot="on">Activated</span>
                            <span slot="off">Unactivated</span>
                        </vs-switch>
                        <div class="clr"></div>
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">ID</td>
                                <td class="p-4">{{ part.sparpart_id || 'Undefined'}}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">English Title</td>
                                <td class=" p-4">{{part.title_en || 'Undefined'}}</td>
                            </tr>
<!--                            <tr>-->
<!--                                <td class="font-semibold p-4">JUMIA Link</td>-->
<!--                                <td class=" p-4">{{part.url || 'Undefined'}}</td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td class="font-semibold p-4">Brand</td>-->
<!--                                <td class=" p-4">{{part.brand || 'Undefined'}}</td>-->
<!--                            </tr>-->
                          <tr>
                            <td class="font-semibold p-4">Model</td>
                            <td class=" p-4">{{part.model || 'Undefined'}}</td>
                          </tr>

                          <tr>
                            <td class="font-semibold p-4">Price</td>
                            <td class=" p-4">{{part.price || 'Undefined'}} LE</td>
                          </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex" vs-justify="center" vs-align="center" vs-lg="5" vs-sm="11" vs-offset="1">
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-2">
                        <vs-switch color="success" class="switch" v-model="activated">
                            <span slot="on">Activated</span>
                            <span slot="off">Unactivated</span>
                        </vs-switch>
                        <div class="clr"></div>
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Year</td>
                                <td class=" p-4">{{part.year || 'Undefined'}}</td>
                            </tr>

                            <tr>
                                <td class="font-semibold p-4">Arabic Title</td>
                                <td class=" p-4">{{part.title_ar|| 'Undefined'}}</td>
                            </tr>
                          <tr>
                            <td class="font-semibold p-4">Category</td>
                            <td class=" p-4">{{part.category || 'Undefined'}}</td>
                          </tr>
<!--                            <tr>-->
<!--                                <td class="font-semibold p-4">Availability</td>-->
<!--                                <td class=" p-4">{{part.availabilty || 'Undefined'}}</td>-->
<!--                            </tr>-->
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>


<script>
    export default {
        name: 'part-info',
        props: {
            part: {
                required: true,
                default: {
                    id: null,
                    sparpart_id: '',
                    title_en: '',
                    title_ar: '',
                    image: '',
                    price: '',
                    url: '',
                    year: '',
                    availabilty: '',
                    brand: '',
                    model: '',
                    category: '',
                    mobile_active: null,
                    web_active: null
                }
            }
        },
        data() {
            return {
                activated: this.part.mobile_active
            };
        },
        watch: {
            activated(val) {
                this.$httpAbout.get(`sparepart/activation/${this.part.id}/mobile`)
                    .then(r => {
                        console.log(r);
                        this.$emit('update');
                        if (val) {
                            this.$vs.notify({
                                title: 'Success',
                                text: 'Item is activated successfully',
                                color: 'primary'
                            });
                        } else {
                            this.$vs.notify({
                                title: 'Success',
                                text: 'Item is deactivated successfully',
                                color: 'primary'
                            });
                        }
                    })
                    .catch(() => {
                        //console.log(e);
                        this.$vs.notify({
                            title: 'Error',
                            text: 'Error is occurred!\nCouldn\'t Save Data!',
                            color: 'danger'
                        });
                    });
            }
        }
    };
</script>

<style scoped lang="scss">
    .switch {
        margin-bottom: 50px;
        float: right;
        padding-right: 45px;
        padding-left: 40px;
    }

    .clr {
        clear: both;
    }

    .product-img {
        height: 110px;
        width: 110px;
        object-fit: cover;
    }
</style>
