<template>
  <div>
    <breadcrumb name="Spare Parts" title="All Spare Parts" subtitle="Edit" @onTitleClicked="$emit('cancel')"/>

    <part-info :part="part" @update="$emit('update')"/>
    <vx-card title="Change Model of Spare Part">
      <choose-model @onBrandChosen="setBrandId" @onModelChosen="setModelId" :selectedModelID="model_id"
                    :key="modelCounter"/>
      <div class="optionBtns">
        <vs-button class="mr-5 save" icon-pack="feather" icon="icon-save"
                   @click="confirmSaveData">Save
        </vs-button>
        <vs-button class="ml-5 cancel" type="border" color="primary" @click="$emit('cancel')">
          Reset
        </vs-button>
      </div>
    </vx-card>

    <!--        <div class="body">-->
    <!--            <div class="english">-->
    <!--                <vs-input label="JUMIA Link" v-model="part.url"-->
    <!--                          size="medium"-->
    <!--                          class="input"/>-->
    <!--            </div>-->
    <!--            <div class="arabic">-->

    <!--            </div>-->
    <!--            <div>-->
    <!--                <div class="clr"/>-->
    <!--            </div>-->
    <!--            <img-upload :images="imagesArray" title="Update Image"-->
    <!--                        @single_upload="addImage" parent="edit-parts"/>-->

    <!--                <div class="optionBtns">-->
    <!--                    <vs-button class="mr-5 save" icon-pack="feather" icon="icon-save"-->
    <!--                               @click="confirmSaveData">Save-->
    <!--                    </vs-button>-->
    <!--                    <vs-button class="ml-5 cancel" type="border" color="primary" @click="$emit('cancel')">-->
    <!--                        Reset-->
    <!--                    </vs-button>-->
    <!--                </div>-->
    <!--            </div>-->
  </div>
</template>

<script>
  import ImgUpload from "../../../../components/general/img-upload";
  import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
  import PartInfo from "./part-info";
  import Breadcrumb from "../../../../components/general/breadcrumb";
  import ChooseModel from "../../../../components/custom/choose-model";

  export default {
    name: "edit-parts",
    components: { ChooseModel, Breadcrumb, PartInfo, ImgUpload, HomeIcon, ChevronsRightIcon },
    props: {
      part: {
        required: true,
        default: {
          id: null,
          sparpart_id: "",
          title_en: "",
          title_ar: "",
          image: "",
          price: "",
          url: "",
          year: "",
          availabilty: "",
          brand: "",
          model: "",
          category: "",
          mobile_active: null,
          web_active: null
        }
      }
    },
    data() {
      return {
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        imagesArray: [],
        image: "",
        activated: this.part.mobile_active,
        models: [],
        brand_id: "0",
        model_id: this.part.model_id,
        modelCounter: 0
      };
    },
    methods: {
      getModels() {
        this.$vs.loading();
        this.$httpCars.get(`/models`)
          .then(res => {
            let total = res.data.data.total;
            console.log({ totalModels: total });
            this.$vs.loading();
            this.$httpCars
              .get(`/models?per_page=${total}`)
              .then(res => {
                this.$vs.loading.close();
                this.models = res.data.data.models;
                console.log({ models: this.models });
            //   this.matchModel();
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error",
                  text: "Couldn't fetch data from the server",
                  color: "danger"
                });
              });
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch data from the server",
              color: "danger"
            });
          });
      },
      matchModel() {
        for (let i in this.models) {
          if (this.models[i].name_en === this.part.model) {
            this.model_id = this.models[i].id;
            this.modelCounter++;
            console.log({brand_name: this.models[i].name_en});
            break;
          }
        }
      },
      setBrandId(id) {
        this.brand_id = id;
      },
      setModelId(id) {
        this.model_id = id;
      },
      addImage(image) {
        this.image = image;
        this.part.image = this.baseImagesURL + image;
        this.imagesArray = [];
        this.imagesArray.push(this.part.image);
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {
        this.$vs.loading();

        let payload = {
          model_id: this.model_id,
        };

        this.$httpAbout.post(`sparepart/${this.part.id}`, payload)
          .then(r => {
            this.$vs.loading.close();
            console.log(r);
            this.$vs.notify({
              title: "Success",
              text: "Data is Saved Successfully",
              color: "primary"
            });
            this.$emit("update");
            this.$emit("cancel");
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      }
    },
    beforeMount() {
      this.imagesArray = [];

      if (this.part.image !== "") {
        this.imagesArray.push(this.part.image);
        console.log(this.part.image);
      }
    },
    mounted() {
      this.getModels();
      console.log({ part: this.part });
    }
  };
</script>

<style scoped lang="scss">
  @import "../../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(50px);
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
    cursor: pointer;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }


</style>
